<template>
  <div class="wrapper">
    <div class="btn-group">
      <div>
        <admin-title :title="$route.meta.name"></admin-title>
      </div>
      <div>
        <el-button type="info" icon="el-icon-back" size="small" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <a-divider />
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
  </div>
</template>

<script>
const columns = [
  {
    label: '客户姓名',
    prop: 'address_dsc',
    minWidth: '130',
    customRender(h, row) {
      return (
        <p class="small-size" title={row['address_dsc']}>
          {row['address_dsc']}
        </p>
      )
    }
  },
  {
    label: '审核人',
    prop: 'role',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['role']}</p>
    }
  },
  {
    label: '客户经理',
    prop: 'name',
    minWidth: '120',
    customRender(h, row) {
      return (
        <p class="small-size" title={row['name']}>
          {row['name']}
        </p>
      )
    }
  },
  {
    label: '订单号',
    prop: 'contact_phone',
    minWidth: '120',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['contact_phone']}>
          {row['contact_phone']}
        </p>
      )
    }
  },
  {
    label: '下单时间',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '在逾金额',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '平台',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '商户',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '支付订单',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '支付时间',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '商品信息',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '单期租金',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '租期',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '总租金',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '买断金',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '溢价金',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '意外保',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '开始时间',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  {
    label: '结束时间',
    prop: 'last_login_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  }
  // {
  //   label: '操作',
  //   width: '300',
  //   fixed: 'right',
  //   customRender(h, row) {
  //     return (
  //       <div>
  //         <el-link underline={false} style="margin-right:20px" type="primary" onClick={() => {}}>
  //           编辑
  //         </el-link>

  //         <el-link underline={false} style="margin-right:20px" type="primary" onClick={() => {}}>
  //           账单详情
  //         </el-link>
  //         <el-popconfirm title="确定删除吗？" onConfirm={() => this.handleDel(row['id'])}>
  //           <el-link underline={false} slot="reference" type="primary">
  //             删除
  //           </el-link>
  //         </el-popconfirm>
  //       </div>
  //     )
  //   }
  // }
]
export default {
  name: 'Detail',

  data() {
    return {
      columns,
      currentPage: 1,
      page_size: 20,
      total: 0,
      list: [{ id: 123 }, { id: 345 }]
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
